import React from "react";
import { Link } from "react-router-dom";
import EventImgOne from "../../assets/img/event5.jpeg";
import EventImgTwo from "../../assets/img/event2.jpeg";
import EventImgThree from "../../assets/img/event3.jpeg";
import EventImgFour from "../../assets/img/event4.jpeg";

export default function HomeEventsSection() {
  return (
    <div className="home-events-main container-fluid p-0">
      <div className="events-banner">
        <h4>upcoming events</h4>
      </div>
      <div className="events-list">
        <div className="custom-container">
          <div className="row">
          <div className="col-md-3 col-12">
              <Link to="/eventsdetail">
                <div className="events-box">
                  <img src={EventImgOne} />
                  {/* <h6>27-09-2024</h6> */}
      
                </div>
              </Link>
            </div>
            <div className="col-md-3 col-12">
              <Link to="/eventsdetail">
                <div className="events-box">
                  <img src={EventImgTwo} />
                  {/* <h6>27-09-2024</h6> */}
      
                </div>
              </Link>
            </div>
            <div className="col-md-3 col-12">
              <Link to="/eventsdetail">
                <div className="events-box">
                  <img src={EventImgThree} />
                  {/* <h6>27-09-2024</h6> */}
  
                </div>
              </Link>
            </div>
            <div className="col-md-3 col-12">
              <Link to="/eventsdetail">
                <div className="events-box">
                  <img src={EventImgFour} />
                  {/* <h6>27-09-2024</h6> */}
  
                </div>
              </Link>
            </div>
            <div className="event-btn">
              <Link className="read-more-btn" to="/events">
                View More{" "}
                <span class="material-symbols-outlined">trending_flat</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
